import React from "react";
import { Card, CardContent, Typography, Button, styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "white",
  fontFamily: "Inter",
  fontWeight: "bold",
  fontSize: "12px",
  backgroundColor: "#A50202",
  textAlign: "center",
  padding: "5px 15px",
  "&:hover": {
    transition: "all",
  },
}));
const StyledTitle = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  fontFamily: "Inter",
  color: "white",
  textTransform: "uppercase",
  fontSize: "20px",
  fontWeight: "bold",
  lineHeight: "1.2",
  paddingBottom: "10px",
  "&:hover": {
    transition: "all",
  },
}));
const StyledSubtitle = styled(Typography)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  fontFamily: "Inter",
  color: "white",
  fontSize: "14px",
  fontWeight: "normal",
  textAlign: "justify",
  lineHeight: "1.2",
  paddingBottom: "15px",
}));
const email = "cerrajeria.24hrs.cas@gmail.com";
const InfoCard = ({ icon, title, text, showButton }) => {
  return (
    <Card
      style={{
        width: "250px",
        height: "350px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "15px",
        padding: "15px",
        justifyContent: "space-around",
        backgroundColor: "#262626",
        boxShadow: "-1px 2px 22px 0px rgba(0,0,0,0.49)",
      }}
    >
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {icon}
        <StyledTitle variant="h6" gutterBottom>
          {title}
        </StyledTitle>
        <StyledSubtitle gutterBottom>{text}</StyledSubtitle>
        {showButton === true ? (
          <StyledButton href={`https://wa.me/+34665907125`} target="_blank">
            Solicitar Presupuesto
          </StyledButton>
        ) : null}
      </CardContent>
    </Card>
  );
};
export default InfoCard;
