import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { AppBar, Button, Box, styled, Menu, MenuItem } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import BurgerMenu from "./BurgerMenu";
import "./Navbar.css";

const StyledButton = styled(Button)(({ theme }) => ({
  listStyle: "none",
  fontFamily: "Inter",
  display: "flex",
  textTransform: "uppercase",
  fontWeight: "normal",
  color: "white",
  fontSize: "15px",
  transition: "all",
  "&:hover": {
    color: "#A50202",
    backgroundColor: "#171717",
  },
}));

const StyledContactButton = styled(Button)(({ theme }) => ({
  margin: 4,
  listStyle: "none",
  display: "flex",
  fontFamily: "Inter",
  backgroundColor: "#40C351",
  color: "black",
  fontSize: "15px",
  fontWeight: "bold",
  padding: "5px 25px",
  "&:hover": {
    color: "white",
    transition: "all",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  transition: "all",
  "&:hover": {
    color: "#A50202",
  },
}));

const Navbar = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar className="navbar-title">
        <div className="button-nav-container">
          <StyledButton component={Link} to="/">
            <img
              src="/logotipo.png"
              alt="plato de ducha"
              loading="lazy"
              className="logo-img"
            />
          </StyledButton>
          {isMobile ? (
            <BurgerMenu />
          ) : (
            <Box className="link-container">
              <StyledButton
                className="navbar-links"
                aria-controls="poblaciones-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                Poblaciones
              </StyledButton>
              <StyledButton
                className="navbar-links"
                component={Link}
                to="/nuestros-servicios"
              >
                Nuestros servicios
              </StyledButton>
              <StyledButton
                className="navbar-links"
                component={Link}
                to="/nosotros"
              >
                Sobre nosotros
              </StyledButton>

              <StyledContactButton
                className="navbar-links"
                component={Link}
                to="/contacto"
              >
                CONTACTO
              </StyledContactButton>
              <Menu
                id="poblaciones-menu"
                style={{ width: "300px" }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "poblaciones-button",
                }}
                PaperProps={{
                  sx: {
                    width: "200px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  },
                }}
              >
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/grao-de-castellon"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Grao de Castellón
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/nules"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Nules
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/almassora"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Almassora
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  to="/villareal"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Villarreal
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/chilches"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Chilches
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  to="/burriana"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Burriana
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/almanera"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Almenara
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/betxi"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Betxi
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  to="/onda"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Onda
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  to="/vall-de-uxo"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Vall de Uxó
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/castellon"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Castellón
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/oropesa"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Oropesa
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/benicassin"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Benicassin
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/alcossebre"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Alcossebre
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/peniscola"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Peñiscola
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/benicarlo"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Benicarló
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/vinaros"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Vinaros
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/torreblanca"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Torreblanca
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/cabanes"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Cabanes
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/ribesalbes"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Ribesalbes
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/alcala-de-xivert"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Alcalá de Xivert
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/alquerias-del-niño"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Alquerías del niño
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/artana"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Artana
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={handleClose}
                  component={Link}
                  // to="/pobla-tornesa"
                >
                  <LocationOnOutlinedIcon sx={{ marginRight: "10px" }} />
                  Pobla tornesa
                </StyledMenuItem>
              </Menu>
            </Box>
          )}
        </div>
      </AppBar>
    </>
  );
};

export default Navbar;
